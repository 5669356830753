import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ObjectUtils from '../../utils/ObjectUtils';
import { Option } from '../Option';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import ConditionGenerator from './ConditionGenerator';
import { Action, ConditionalAndOr, FormBuilderForm } from './FormBuilderTypes';

type ConditionCombinerProps = {
  form: FormBuilderForm;
  action: Action;
  actionCondition: string;
  disabled?: boolean;
  lockToAction?: boolean;
  invalidStates?: Record<string, string[]>;
  onConditionUpdated: (condition: string) => void;
  lockToSectionId?: string;
};

const splitConditional = (conditional: string) => {
  const parts: string[] = [];

  const splitConditions = (conditional || '')
    .split(/\s(and|or)\s?/)
    .map((x) => x.trim())
    .filter((x) => x);

  let i = 0;
  for (const cond of splitConditions) {
    parts[i] = ((parts[i] || '') + ' ' + cond).trim();
    if (cond === ConditionalAndOr.AND || cond === ConditionalAndOr.OR) {
      i++;
    }
  }

  return parts;
};

const ConditionCombiner: FC<ConditionCombinerProps> = (props) => {
  const { form, action, disabled, actionCondition, onConditionUpdated, lockToAction, invalidStates, lockToSectionId } = props;
  const [conditions, setConditions] = useState<string[]>([]);

  const { t } = useTranslation('form-builder');

  const andOrOptions = useMemo<Option<string, string | number>[]>(
    () => [
      { id: ConditionalAndOr.NONE, text: t('action-properties.common.none-option'), value: ConditionalAndOr.NONE },
      { id: ConditionalAndOr.AND, text: t('action-properties.common.conditionals.and'), value: ConditionalAndOr.AND },
      { id: ConditionalAndOr.OR, text: t('action-properties.common.conditionals.or'), value: ConditionalAndOr.OR },
    ],
    [t],
  );

  useEffect(() => {
    const newConditions = splitConditional(actionCondition);
    if (
      !newConditions.length ||
      newConditions[newConditions.length - 1].endsWith(` ${ConditionalAndOr.AND}`) ||
      newConditions[newConditions.length - 1].endsWith(` ${ConditionalAndOr.OR}`)
    ) {
      newConditions.push('');
    }
    setConditions(newConditions);
  }, [actionCondition]);

  const updateCondition = (index: number, condition: string) => {
    const newConditions = conditions
      .slice(0, index + 1)
      .map((x, i) => (i === index ? condition : x))
      .filter((x) => !!x);
    if (newConditions.length === 0) {
      newConditions.push('');
    }
    setConditions(newConditions);

    const joinedCondition = newConditions.join(' ');
    onConditionUpdated(joinedCondition);
  };

  const andOrChanged = (index: number, andOr: string) => {
    let newConditions = ObjectUtils.DeepClone(conditions).slice(0, index + 1);

    newConditions = newConditions.map((x, i) => {
      if (i === index) {
        if (x.endsWith(' ' + ConditionalAndOr.AND)) {
          x = x.substring(0, x.length - ConditionalAndOr.AND.length - 1);
        } else if (x.endsWith(' ' + ConditionalAndOr.OR)) {
          x = x.substring(0, x.length - ConditionalAndOr.OR.length - 1);
        }
      }
      return i === index ? (x + ` ${andOr}`).trim() : x;
    });

    setConditions(newConditions);
    const joinedCondition = newConditions.join(' ');
    onConditionUpdated(joinedCondition);
  };

  return (
    <div data-cy="condition-combiner">
      {conditions.map((condition, i) => (
        <div key={i} className="flex flex-col items-start gap-2 pt-2" data-cy={`condition-${i}`}>
          <div className="w-full">
            <ConditionGenerator
              disabled={disabled}
              action={action}
              form={form}
              condition={condition}
              lockToAction={lockToAction}
              updateCondition={(condition) => updateCondition(i, condition)}
              invalidStates={invalidStates}
              lockToSectionId={lockToSectionId}
            />
          </div>
          <div className="w-28">
            <DropdownSelect
              data-cy="condition-chaining-select"
              disabled={disabled || condition.split(' ').length <= 2}
              placeholder=""
              options={andOrOptions}
              onChange={(option) => andOrChanged(i, option.id)}
              value={
                conditions[i].endsWith(ConditionalAndOr.AND)
                  ? { id: ConditionalAndOr.AND, text: t('action-properties.common.conditionals.and'), value: ConditionalAndOr.AND }
                  : conditions[i].endsWith(ConditionalAndOr.OR)
                    ? { id: ConditionalAndOr.OR, text: t('action-properties.common.conditionals.or'), value: ConditionalAndOr.OR }
                    : { id: '', text: '', value: '' }
              }
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ConditionCombiner;
